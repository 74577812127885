/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var $hideEmptyPs = (function() {

          var $ps = $('#course-details > .container > .row > .col-md-8 p:first-child'),

          init = function() {
            $ps.each(function() {
              console.log($.trim($(this).html()));
              if( $.trim($(this).html()) == '&nbsp;' ) {
                $(this).hide();
              }
            });
          };

          return {init: init};

        })();

        $hideEmptyPs.init();

        $('.book-course-list table').stacktable();

        var $autoComplete = (function() {

          var $field = $('.main-search .search-field'),

          init = function() {
            $field.autocomplete({
              source: $field.data('course')
            });
          };

          return {init: init};

        })();

        $autoComplete.init();
        
        var $megaMenu = (function() {

          var $dropdowns = $('.nav-primary .dropdown-menu'),

          init = function() {
            $dropdowns.each(function() {
              var $children = $(this).children();
              $children.css('width', (100 / Math.max($children.length, 4)) + '%');
            });
          };

          return {init: init};

        })();

        $megaMenu.init();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.testimonials-carousel').owlCarousel({
          margin: 10,
          nav: false,
          dots: true,
          responsive: {
            0: {
              items: 3
            }
          }
        });

        $('.partner-carousel').owlCarousel({ 
          margin: 10,
          nav: false,
          dots: true,
          loop: true,
          center: true,
          autoplay: true,
          responsiveClass:true,
          responsive: {
               0    : { items:1 },
               768  : { items:2 },
               991  : { items:3 },
               1199 : { items:5 }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


  $(function() {
    $(".full-desc-button").click(function() {
      $(".additional-content-holder").toggleClass("additional-content-holder-open");
    });
  });

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.